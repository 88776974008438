import { h, render, Fragment } from 'preact';

export default function Content() {
    return (
        <div className="relative bg-white font-merri">
            <div className="mx-auto text-center max-w-md pt-20 pb-8 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <div data-aos="fade-up" data-aos-duration="800" data-aos-easing="linear">
                    <h2 className="text-4xl font-semibold tracking-wider text-[#4a4a4a]">
                        Why Bison Cash?
                    </h2>
                    <p className="mt-5 text-lg text-[#4a4a4a]">
                        When unexpected expenses or emergencies arise,
                        Bison Cash will get you the cash you need in
                        order for you to <span className="text-green-500">quickly get back</span> on your feet.
                        When you need cash, Bison Cash is your <span className="text-green-500">number one choice</span>.
                    </p>
                </div>
                <div className="flex justify-center items-center">
                    <img className="h-[auto] lg:h-[45rem]" src="/public/src/img/bsc_partner_test_3.png" data-aos="fade-up" data-aos-duration="800" data-aos-anchor-placement="center-bottom" data-aos-easing="linear" />
                </div>
            </div>
        </div>
    );
}
