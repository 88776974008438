import "css/styles.css";
import { h, render, Fragment } from "preact";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
} from "react-router-dom";
import AuthProvider from "js/components/User/AuthProvider.js";
import "js/shared/logging.js";
import Routes from "js/routes.js";
const React = require("react");
const ReactDOM = require("react-dom");

if (process.env.NODE_ENV !== "production") {
    // const axe = require('@axe-core/react');
    // axe(React, ReactDOM, 1000);
}

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes />
            </Router>
        </AuthProvider>
    );
};

render(<App />, document.getElementById("app"));
