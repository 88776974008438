import { h, render, Fragment } from 'preact';

export default function Featured() {
    return (
        <div className="relative bg-white pb-16 pt-[1600px] sm:pb-[6rem] md:pt-[1300px] lg:pt-[350px] lg:pb-35 2xl:pt-[25rem] 3xl:pt-[20rem] font-merri">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                <div data-aos="fade-up" data-aos-duration="800" data-aos-easing="linear">
                <h2 className="text-4xl font-semibold tracking-wider text-[#4a4a4a]">
                    Easy to Borrow, Easy to Lend
                </h2>
                <p className="mt-5 text-lg text-[#4a4a4a]">
                    Bison Cash simplifies the process of lending and borrowing installment loans, making it <span className="text-green-500">hassle-free</span> and <span className="text-green-500">accessible</span> for users.
                </p>
                </div>
                <div>
                    <img className="h-[auto] mx-auto mb-[1rem] sm:h-[35rem] 3xl:h-[40rem]" src="/public/src/img/bsc_apply_test_2.png" data-aos="fade-up" data-aos-duration="800" data-aos-anchor-placement="center-bottom" data-aos-easing="linear" />
                    <a
                        href="/apply-now"
                        className="transition-colors duration-300 ease-in-out text-white bg-[#3cae5a] hover:bg-green-600 no-underline font-inter text-center px-[3rem] py-[1rem] rounded-md shadow-sm text-lg font-medium"
                        style="box-shadow: rgb(60 174 90) 0px 10px 40px -5px;"
                        data-aos="fade-up" data-aos-duration="800" data-aos-anchor-placement="center-bottom" data-aos-easing="linear"
                    >
                        Get Started
                    </a>
                </div>
            </div>
        </div>
    );
}
